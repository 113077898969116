const prefix = 'cliente_andamios';
export default{
	clear: function(){
		localStorage.clear();
	},
	start: function(data){
		localStorage.setItem(prefix+'_usuario_nombre', data.nombre_usuario || '');
		localStorage.setItem(prefix+'_usuario_apellido_paterno', data.apellido_p || '');
		localStorage.setItem(prefix+'_usuario_apellido_materno', data.apellido_m || '');
		localStorage.setItem(prefix+'_usuario_correo', data.correo_usuario || '');
		localStorage.setItem(prefix+'_usuario_clave_usuario', data.clave_usuario || '');

		localStorage.setItem(prefix+'_usuario_id', data.clave_usuario || '');
		localStorage.setItem(prefix+'_usuario_token', data.clave_api|| '');
		localStorage.setItem(prefix+'_usuario_imagen', data.foto || '');
		localStorage.setItem(prefix+'_usuario_modulos', data.modulos ? JSON.stringify(data.modulos) : null );
	},
	setModulos: function(mods){		
		localStorage.setItem(prefix+'_usuario_modulos', mods ? JSON.stringify(mods) : '');
	},
	getModulos: function(completo){
		try{
			var strMods = localStorage.getItem(prefix+'_usuario_modulos');
			if(strMods &&  strMods != "undefined"){
				var mods = JSON.parse(strMods) || [];
				return completo ? mods : mods.map((item)=>{ return item.identificador; });
			}else{
				return [];
			}
		}catch(error){
			console.log(error);
			return [];
		}
	},
	get: function(key, isObj){
		var val = localStorage.getItem(prefix+'_usuario_'+key) || null;
		return (val === "null" || val === "undefined") ? null : ( isObj ? JSON.parse(val) : val);
	},
	exists: function(){
		return localStorage.getItem(prefix+'_usuario_id') != null;
	},
	authToken: function(){
		return localStorage.getItem(prefix+'_usuario_token') || '';
	},
	set: function(key, value, isObj){
		localStorage.setItem(prefix+'_usuario_'+key, isObj ? JSON.stringify(value) : value);
	},
	setFlashValue: (key, value) => {
		sessionStorage.setItem(key, value || '');
	},
	getFlashValue: (key, value) => {
		let v = sessionStorage.getItem(key) || value || '';
		sessionStorage.removeItem(key);
		return v;
	}
};