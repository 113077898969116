import axios, { post, put } from 'axios';
import {session} from './';
import moment from 'moment';
import { toast } from 'react-toastify';

// const baseURLImagen = process.env.NODE_ENV == 'development' ? 'http://192.168.10.38/simposio/public/upload' : 'https://fmat-umt.online/admin/public/upload';
// const baseURL = process.env.NODE_ENV == 'development' ? 'http://192.168.10.38/simposio/service': 'https://fmat-umt.online/admin/service';
// const baseURLCatalogo = process.env.NODE_ENV == 'development' ? 'http://192.168.10.38/simposio/service' : 'https://fmat-umt.online/admin/service';

const baseURLImagen   = process.env.REACT_APP_BASE_URL_IMAGEN
const baseURL         = process.env.REACT_APP_BASE_URL
const baseURLCatalogo = process.env.REACT_APP_BASE_URL_CATALOGO

const manejarVerificacionError = (error) => {
	if(error && error.response && error.response.status == 401){
		var data = error.response.data;
		toast.error((data.meta ? data.meta.message : data.mensaje) || 'Su sesión ha expirado por seguridad');
		// session.clear();
		// session.setFlashValue('error_logout_message', (data.meta ? data.meta.message : data.mensaje));
		// window.location.reload();
	}
};

const api = axios.create({
  baseURL: baseURL,
});

const api_catalogo = axios.create({
	baseURL: baseURLCatalogo,
});

function getHeaders(){
	return {
		// 'Authorization': "Bearer "+session.get('token'),
		'userapikey': session.get('token'),
		'app': 'cweb',
	};
}

api.interceptors.request.use(function (config) {
	
	var hh = getHeaders();
	for(var k in hh){
		if(hh[k] != null){
			config.headers[k] = hh[k];
		}
	}
	return config;
}, function (error) {
	return Promise.reject(error);
});

api_catalogo.interceptors.request.use(function (config) {
	
	var hh = getHeaders();
	for(var k in hh){
		if(hh[k] != null){
			config.headers[k] = hh[k];
		}
	}
	return config;
}, function (error) {
	return Promise.reject(error);
});

export default {
	axios: api,
	baseURL: baseURL,
	baseURLImagen: baseURLImagen,
	getUrlImage: (path) => {
		return baseURLImagen + ((path.startsWith('/')||path.startsWith('\\')) ? '' : '/') + path;
	},
	getUrlResource: (path) => {
		return path ? (baseURLImagen + ((path.startsWith('/')||path.startsWith('\\')) ? '' : '/') + path) : null;
	},
	url: (path, incluirToken, params) => {
		var append = '';
		if(incluirToken){
			if(path.indexOf('?') !== -1){
				append += '&access_token=' + session.authToken();
			}else{
				append += '?access_token=' + session.authToken();
			}
		}

		if(params){
			if (append.indexOf('?') === -1){
				append += "?";
			}

			Object.keys(params).forEach(key => {
				append += "&" + key + "=" + params[key];
			});

			append = encodeURI(append);
		}
		return baseURL + path + append;
	},
	post: function(path, data, isMultipart, extraConfig){
		return new Promise((resolve, reject) => {
			if(data != null && isMultipart){
				var data2 = new FormData();
				
				for(var key in data){
					if(Array.isArray(data[key])){						
						for(var i = 0; i< data[key].length; i++){
							if( data[key][i] instanceof File || data[key][i] instanceof Blob ){
								data2.append(key, data[key][i]);								
							}else{
								Object.keys(data[key][i]).forEach(key2 => {
									if (data[key][i][key2] && data[key][i][key2] instanceof Date){
										var strVal = moment(data[key][i][key2]).format('YYYY-MM-DD HH:mm:ss');
										data2.append([key + '[' + i + '][' + key2 + ']'], strVal);
									}else{
										data2.append(
											[key+'['+i+']['+key2+']'],
											data[key][i][key2]
										);
									}
								});
							}
						}
					}else{
						if (data[key] && data[key] instanceof Date){
							var strVal = moment(data[key]).format('YYYY-MM-DD HH:mm:ss');
							data2.append(key, strVal);
						}else{							
							data2.append(key, data[key]);
						}
					}
				}
				
				var config = { headers: getHeaders() };
				if (extraConfig){
					config = {
						headers: getHeaders(),
						...extraConfig
					};
				}

				config.headers['content-type'] = 'multipart/form-data';				
				// for (var key of data2.entries()) {
				// 	console.log(key[0] + ', ' + key[1]);
				// }
				post(baseURL+path, data2, config)
				.then(resource => {
					resolve(resource.data || {});
				}).catch(error => {
					manejarVerificacionError(error);
					reject(error);
				});
			}else{
				api.post(path, data)
				.then(resource => {
					resolve(resource.data || {});
				}).catch(error => {
					manejarVerificacionError(error);
					reject(error);
				});
			}
		});
	},
	get: function(path, params){
		return new Promise((resolve, reject) => {
			api.get(path, { params: params ? params: {} })
			.then(resource => {
				resolve(resource.data || {});
			}).catch(error => {
				manejarVerificacionError(error);
				reject(error);
			});
		});
	},
	put: function (path, data, isMultipart, extraConfig){
		return new Promise((resolve, reject) => {
			if(data != null && isMultipart){
				var data2 = new FormData();
				for(var key in data){
					if(Array.isArray(data[key])){
						for(var i = 0; i< data[key].length; i++){
							if( data[key][i] instanceof File || data[key][i] instanceof Blob ){
								data2.append(key, data[key][i]);								
							}else{
								Object.keys(data[key][i]).forEach(key2 => {
									if (data[key][i][key2] && data[key][i][key2] instanceof Date) {
										var strVal = moment(data[key][i][key2]).format('YYYY-MM-DD HH:mm:ss');
										data2.append([key + '[' + i + '][' + key2 + ']'], strVal);
									} else {
										data2.append(
											[key + '[' + i + '][' + key2 + ']'],
											data[key][i][key2]
										);
									}
								});
							}
						}
					}else{
						if (data[key] && data[key] instanceof Date) {
							var strVal = moment(data[key]).format('YYYY-MM-DD HH:mm:ss');
							data2.append(key, strVal);
						} else {
							data2.append(key, data[key]);
						}
					}
				}
				
				var config = { headers: getHeaders() };
				if (extraConfig) {
					config = {
						headers: getHeaders(),
						...extraConfig
					};
				}

				config.headers['content-type'] = 'multipart/form-data';

				put(baseURL+path, data2, config)
				.then(resource => {
					resolve(resource.data || {});
				}).catch(error => {
					manejarVerificacionError(error);
					reject(error);
				});
			}else{
				api.put(path, data)
				.then(resource => {
					resolve(resource.data || {});
				}).catch(error => {
					manejarVerificacionError(error);
					reject(error);
				});
			}
		});
	},
	delete: function(path, params){
		return new Promise((resolve, reject)=>{
			api.delete(path, params)
			.then(resource => {
				resolve(resource.data || {});
			}).catch(error => {
				manejarVerificacionError(error);
				reject(error);
			});
		})
	},
	getDataPagination: function(path, params){
		return new Promise((resolve, reject)=>{
			api.get(path, { params: params ? params: {} })
			.then(resource => {
				var resp = resource.data;
				if(resp.status){
					resp.data.pages = Math.ceil(parseFloat(resp.data.count || 0 ) / parseFloat(params.count || 10));
				}
				resolve(resp);
			}).catch(error => {
				manejarVerificacionError(error);
				reject(error);
			});
		});
	},
	getCatalogo: function(path, params){
		return new Promise((resolve, reject) => {
			api_catalogo.get(path, { params: params ? params: {} })
			.then(resource => {
				resolve(resource.data || {});
			}).catch(error => {
				manejarVerificacionError(error);
				reject(error);
			});
		});
	},
}