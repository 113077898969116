import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody} from 'reactstrap';

class ModalConfirmacion extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
        	isOpen: true,
        };
        this.cerrarModal = this.cerrarModal.bind(this);
        this.confirmarModal = this.confirmarModal.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState({ isOpen: nextProps.isOpen ? true : false });
    }

    cerrarModal(){
    	this.setState({isOpen: false});
    	this.props.onCancel();
    }

    confirmarModal(){
		this.setState({isOpen: false});
		this.props.onConfirm();
    }
	
    render() {
		
        return (
        	this.props.isOpen ? 
			<Modal isOpen={ this.state.isOpen } toggle={this.cerrarModal} className={'modal-'+(this.props.modalType || 'primary')}>
				<ModalHeader toggle={this.cerrarModal}>{ this.props.modalTitle || 'Confirmación'}</ModalHeader>
              	<ModalBody>
                	{this.props.modalBody}
              	</ModalBody>
              	<ModalFooter>
                	<Button type="button" color="secondary" onClick={this.cerrarModal}>{ this.props.modalBtnCancel || 'Cancelar'}</Button>
                	<Button type="button" color={this.props.modalType || 'primary'} onClick={ this.confirmarModal }> <i className={'fa fa-'+(this.props.modalFaIcon || 'check')}></i> {this.props.modalBtnConfirm || 'Aceptar'}</Button>{' '}
              	</ModalFooter>
            </Modal>
            :
            null
        );
    }
}

export default ModalConfirmacion;
