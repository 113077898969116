import React, { Component } from 'react';
import { HashRouter, Route, Switch, BrowserRouter } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import { Provider } from 'react-redux';
import store from './redux/store';
import './App.scss';
import AxiosLoader from './utils/AxiosLoader';
import ReactTooltip from 'react-tooltip'

import {LoaderComponent} from './utils';

// para toast
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import DatePicker, { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import { setLocale } from 'yup';

import trLocale from 'moment/locale/es';
import moment from 'moment';

moment.locale('es', trLocale);

registerLocale('es-MX', es);

const printValue = (value, boo) => {
  return value;
}

setLocale({
  // use constant translation keys for messages without values
  mixed: {
    default: '${path} es inválido',
    required: '${path} es un campo requerido',
    oneOf: '${path} debe ser uno de los siguientes valores: ${values}',
    notOneOf: '${path} no debe ser uno de los siguientes valores: ${values}',
    notType: ({ path, type, value, originalValue }) => {
      let isCast = originalValue != null && originalValue !== value;
      let msg =
        `${path} debe ser de tipo \`${type}\`, ` +
        `pero el valor actual es: \`${printValue(originalValue, true)}\`` 
        // (isCast
        //   ? ` (cast from the value \`${printValue(originalValue, true)}\`).`
        //   : '.')
          ;

      if (value === null) {
        msg += `\n Si "null" es considerado como valor vacío entonces asegurate de marcar el esquema como \`.nullable()\``;
      }

      return msg;
    },
  },
  // use functions to generate an error object that includes the value from the schema
  number: {
    min: '${path} debe ser mayor o igual que ${min}',
    max: '${path} debe ser menor o igual que ${max}',
    lessThan: '${path} debe ser menor que ${less}',
    moreThan: '${path} debe ser mayor que ${more}',
    notEqual: '${path} no debe ser igual que ${notEqual}',
    positive: '${path} debe ser un número positivo',
    negative: '${path} debe ser un número negativo',
    integer: '${path} debe ser un número',
  },
  date: {
    min: '${path} debe ser mayor o igual a ${min}',
    max: '${path} debe ser menor o igual a ${max}',
  },
  string: {
    length: '${path} debe tener exactamente ${length} caracteres',
    min: '${path} debe tener al menos ${min} caracteres',
    max: '${path} debe tener como máximo ${max} caracteres',
    // matches: '${path} must match the following: "${regex}"',
    matches: '${path} no tiene un formato válido',
    email: '${path} debe ser un correo válido',
    url: '${path} debe ser una URL válida',
    trim: '${path} debe ser un texto sin espacios',
    lowercase: '${path} debe estar en minúsculas',
    uppercase: '${path} debe estar en mayúsculas',
  },
  array:{
    min: 'De agregar al menos ${min} registro(s) al listado de ${path}(s)'
  }
});

// google maps
// import { GoogleApiWrapper } from 'google-maps-react';

const loading = LoaderComponent;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./components/Pages/Login'));
const Register = React.lazy(() => import('./components/Pages/Register'));
const Page404 = React.lazy(() => import('./components/Pages/Page404'));
const Page500 = React.lazy(() => import('./components/Pages/Page500'));
const Activate = React.lazy(() => import('./components/Pages/Activate/Activate'));
const Recovery = React.lazy(() => import('./components/Pages/Activate/Recovery'));

class App extends Component {

  render() {

    const RouterTag = ({ children }) => {
      if( process.env.NODE_ENV == 'development' ){
        return <HashRouter>{ children }</HashRouter>
      }else{
        return <BrowserRouter>{ children }</BrowserRouter>
      }
    }

    return (
      <Provider store={store}>
        <div>
          <AxiosLoader>
            <ToastContainer pauseOnFocusLoss={false} />
            <RouterTag>
              <React.Suspense fallback={loading()}>
                <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                  <Route exact path="/registro" name="Register Page" render={props => <Register {...props}/>} />
                  <Route exact path="/activar" name="Activar cuenta" render={props => <Activate {...props}/>} />
                  <Route exact path="/recuperar" name="Recuperar cuenta" render={props => <Recovery {...props}/>} />
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                  <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                  <Route path="/" name="Home" render={props => <DefaultLayout {...props}/>} />
                </Switch>
              </React.Suspense>
            </RouterTag>
          </AxiosLoader>
          {/* <ReactTooltip /> */}
        </div>
      </Provider>
    );
  }
}

export default App;
