import React, { Component } from 'react';
import moment from 'moment';

class FormatoFecha extends Component {
    constructor(props){
        super(props);
        if(this.props.value){
            var m = moment(this.props.value);
            if(m.isValid()){
                this.state = {
                    fecha: m.format(this.props.formato || this.props.format || 'DD/MM/YYYY'),
                    prepend: this.props.prepend || '',
                    append: this.props.prepend || '',
                };
            }
        }else{
            this.state={
                fecha: this.props.default || '',
                prepend: this.props.prepend || '',
                append: this.props.prepend || '',
            };
        }
    }

    componentWillReceiveProps(nextProps){
        var nuevaFecha = nextProps.value;
        var strF = "-";
        if(nuevaFecha){
            var m = moment(nuevaFecha);
            if(m.isValid()){
                strF = m.format(this.props.formato || this.props.format || 'DD/MM/YYYY');
            }
        }
        this.setState({
            fecha: strF,
            prepend: this.props.prepend || '',
            append: this.props.prepend || '',
        })
    }

    render() {
        return (
            <span>{ this.state.prepend+' '+this.state.fecha+' '+this.state.append }</span>
        );
    }
}

export default FormatoFecha;