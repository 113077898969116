import React, { Component } from 'react';
import { Modal, ModalFooter, ModalHeader, ModalBody, Button, Fade } from 'reactstrap';

class ModalPreviewDocument extends Component {

    constructor(props){
        super(props);

        this.state = {
            loading: false,
            url: '',
        };

        this.onIframeLoad = this.onIframeLoad.bind(this);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.url){
            this.setState({
                loading: true,
            }, () => {
                this.setState({
                    // loading: false,
                    url: nextProps.url,
                });
            })
        }else{
            this.setState({
                loading: true,
                url: '',
            })
        }
    }

    onIframeLoad(){
        // console.log("=========> loaded: ");
        this.setState({ loading: false });
    }

    render() {

        // const FormatoObjeto = () => {
        //     return (
        //         this.state.url ? 
                    
        //         : null
        //     )
        // }

        return (
            <div>
                <Modal backdrop="static" size="lg" style={{ maxWidth: '1600px', width: '80%' }} isOpen={this.props.isOpen} toggle={this.props.onClose} className={'modal-info'}>
                    <ModalHeader toggle={this.props.onClose}>{this.props.title || 'Ver documento'}</ModalHeader>
                    {
                        this.state.loading ?
                            <ModalBody>
                                <div className="text-center">
                                    <h5>Espere un momento por favor, se está generando el formato</h5>
                                    <i className="fa fa-spin fa-circle-o-notch"></i>
                                </div>
                            </ModalBody>
                        : null
                    }
                    <div>
                        <iframe src={this.state.url ? this.state.url : null}
                            title="Formato"
                            style={{
                                width: '100%',
                                height: '600px',

                            }}
                            onLoad={this.onIframeLoad}
                        />
                    </div>
                    <ModalFooter>
                        <Button disabled={this.state.loading} type="button" color="secondary" onClick={this.props.onClose}>Cerrar</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default ModalPreviewDocument;