import moment from 'moment';
import queryString from 'query-string';
import cloneDeep from 'lodash/cloneDeep';
import { api } from '../utils';

var utils = {
    crearCopia: (inObject) => {
        if(inObject){
            return cloneDeep(inObject);
        }
        return {};
    },
    getParam: function(props, name){
        return (props &&  props.match && props.match.params) ? props.match.params[name] : null;
    },
    getQuery: (props, name) => {
        const vv = queryString.parse(props.location.search);
        return vv ? vv[name] : '';
    },
    queryObjectPrefixParams: (prefixKey, paramsObject) => {
        var outputParams = {};
        if(paramsObject){
            Object.keys(paramsObject).map((key) => {
                if (paramsObject[key]){
                    outputParams[prefixKey+"[" + key + "]"] = paramsObject[key];
                }
            });
        }
        return outputParams;
    },
    randomString : (l) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        var length = l || 10;
        for ( var i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    },
    safeConcatFormat: (obj, columns, separator) => {
        if(separator == null){
            separator = ' ';
        }
        var tokens = [];
        if(obj && typeof obj == 'object'){
            columns.forEach(item => {
                if(obj[item]){
                    tokens.push(obj[item]);
                }
            });
        }
        return tokens.join(separator).trim();
    },
    safeDateFormat: (strDate, strFormat) => {
        if(strDate){
            var date = moment(strDate);
            if(date && date.isValid()){
                return date.format(strFormat || 'DD/MM/YYYY');
            }
        }
        return '';
    },
    getListaEstatusRentaEquipo: () => {
        return [
            {id: 'cancelado', label: 'Cancelado'},
            {id: 'incompleto', label: 'Incompleto'},
            {id: 'pendiente', label: 'Pendiente'},
            {id: 'aprobado', label: 'Aprobado'},
            {id: 'salida_parcial', label: 'Salida parcial'},
            {id: 'salida', label: 'Salida'},
            {id: 'entrada_parcial', label: 'Entrada parcial'},
            {id: 'entrada', label: 'Entrada'},
        ];
    },
    getListaEstatusRenta: () => {
        return [
            { id: 'cancelado', label: 'Cancelado' },
            { id: 'incompleto', label: 'Incompleto' },
            { id: 'pendiente', label: 'Pendiente de aprobación' },
            { id: 'proceso_entrega', label: 'En proceso de entrega' },
            { id: 'salida', label: 'Salida (En renta)' },
            // { id: 'salida', label: 'Renta activa (salida)' },
            { id: 'entrada', label: 'Entrada (Renta finalizada)' },
        ]
    },
    getListaTipoMovimientoBodega: () => {
        return [
            { id: 'salida_ajuste_fisico', label: 'Salida por ajuste al físico' },
            { id: 'entrada_activo', label: 'Entrada de activo' },
            { id: 'venta_activo', label: 'Venta de Activo' },
            { id: 'baja_activo', label: 'Baja de activo' },
        ];
    },
    getURL : ( value ) => {
        var url = '';
        if( value ){
            if(value instanceof File || value instanceof Blob){
                url = URL.createObjectURL(value);
            }else{
                url = api.getUrlImage(value);
                // url = api.baseURLImagen + ( (value.charAt(0) === '/' || value.charAt(0) === '\\') ? value : ('/'+value));
            }
        }
        return url;
    },
    isValidFormikArrayField: (formik, index, array_name, field_name) => {
        // valid={formik.touched.requisitos && formik.touched.requisitos[index] && formik.touched.requisitos[index].cantidad_solicitada && (!formik.errors.requisitos || !formik.errors.requisitos[index] || !formik.errors.requisitos[index].cantidad_solicitada)}
        // invalid={(formik.touched.requisitos && formik.touched.requisitos[index] && formik.touched.requisitos[index].cantidad_solicitada && formik.errors.requisitos && formik.errors.requisitos[index] && formik.errors.requisitos[index].cantidad_solicitada) ? true : false}
        if (array_name) {
            return formik.touched[array_name] &&
                formik.touched[array_name][index] &&
                formik.touched[array_name][index][field_name] &&
                (
                    !formik.errors[array_name] ||
                    !formik.errors[array_name][index] ||
                    !formik.errors[array_name][index][field_name]
                );
        } else {
            return !formik.errors[field_name] && formik.touched[field_name];
        }
    },
    isInvalidFormikArrayField: (formik, index, array_name, field_name) => {
        if (array_name) {
            return (
                formik.touched[array_name] &&
                formik.touched[array_name][index] &&
                formik.touched[array_name][index][field_name] &&
                formik.errors[array_name] &&
                formik.errors[array_name][index] &&
                formik.errors[array_name][index][field_name]
            ) ? true : false;
        } else {
            return formik.errors[field_name] && formik.touched[field_name];
        }
    },
    getErrorFormikFieldArray: (formik, index, array_name, field_name) => {
        if (utils.isInvalidFormikArrayField(formik, index, array_name, field_name)) {
            return array_name ? formik.errors[array_name][index][field_name] : formik.errors[field_name];
        } else {
            return '';
        }
    },
    dateFormatPart: (str, part, longFormat) => {
        if(str){
            var date = moment(str);
            if(date && date.isValid()){
                if(longFormat){
                    if(part == 'month'){
                        var mm = date.get('month');
                        switch(mm){
                            case 0:
                                return 'Enero';
                            case 1:
                                return 'Febrero';
                            case 2:
                                return 'Marzo';
                            case 3:
                                return 'Abril';
                            case 4:
                                return 'Mayo';
                            case 5:
                                return 'Junio';
                            case 6:
                                return 'Julio';
                            case 7:
                                return 'Agosto';
                            case 8:
                                return 'Septiembre';
                            case 9:
                                return 'Octubre';
                            case 10:
                                return 'Noviembre';
                            case 11:
                                return 'Diciembre';
                            default:
                                return '--';
                        }
                    }else{
                        return '--';
                    }
                }else{
                    return date.get(part);
                }
            }
        }
        return '';
    },
    substring3Points: (str, maxLength) => {
        if(str && str.length > maxLength){
            return str.substring(0, maxLength)+"...";
        }
        return str || '';
    }
};

export default utils;